
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { themeName } from "@/core/helpers/documentation";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import Notice from "@/components/sections/Notice.vue";

export default defineComponent({
  name: "build",
  components: {
    CodeHighlighter,
    Notice
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Build");
    });

    return {
      themeName
    };
  }
});
